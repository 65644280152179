import { Box, Grid } from '@mui/material';
import logo_carbometro from '../assets/carbo-metro-images/carbo-metro-rounded.png';

import Typewriter from "typewriter-effect";


const Header = (props: { titleString: string }) => {
  const border = '0px solid';
  const borderBottom = '2px #ffc107ff solid';
  return (
    <Box display="flex" justifyContent="center">
      <Grid className='app-header-carbo-metro' container spacing={2} border={border} >
        <Grid item xs={12} md={3} border={border} style={{ display: 'flex', justifyContent: 'center' }} borderBottom={borderBottom}>
          <img src={logo_carbometro} className="app-logo-carbo-metro" alt="logo" />
        </Grid>
        <Grid item xs={12} md={9} border={border} className='CarboMetro-Slogan-Section' borderBottom={borderBottom}>
          <div  >
            <Typewriter
              // options={{ strings: ['Carbo Metro', props.titleString], autoStart: true }}
              onInit={(typewriter) => {

                typewriter.pauseFor(1000)
                  .typeString('Carbo Metro')
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString(props.titleString)
                  .start()
                  .pauseFor(5000);
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Header;
