
import Footer from './component/Footer';
import { Routes, Route } from "react-router-dom";
import Home from './component/Home';
import Support from './component/Support';
import TermAndUseConditions from './component/TermAndUseConditions';
import ResponsiveAppBar from './component/AppBar';
import { createTheme, ThemeProvider } from "@mui/material/styles";

function App() {

  const takTheme = createTheme({
    palette: {
      primary: {
        main: "#ffc107ff",
        contrastText: "#000",
        // textColor: "#000",
      },
    },
    components: {
      // MuiAppBar: {
      //   styleOverrides: {
      //     root: {
      //       color: "#000",
      //     },
      //   },
      // },
    },
  });

  return (
    <>
      <ThemeProvider theme={takTheme}>
        <ResponsiveAppBar />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/support' element={<Support />}></Route>
          <Route path='/terms-and-use-conditions' element={<TermAndUseConditions />}></Route>
        </Routes>
        <Footer />
      </ThemeProvider>
    </>)
}

export default App;
