import Header from './Header';
import '../assets/css/AppCarboMetro.css';

import { Box, Grid, Button } from '@mui/material';

function Support() {
  const border = '0px solid';

  return (
    <div className="App-CarboMetro">
      <Box display="flex" justifyContent="center" style={{ paddingTop: '10px' }}>
        <Grid className='app-header-carbo-metro' container spacing={2} border={border}>
          <Grid item xs={12} border={border}>
            <Header titleString="hai bisogno di aiuto?" />
          </Grid>
          <Grid item xs={12} border={border}>
            <h1>Contattaci</h1>
            <p>
              Se hai dei dubbi su come funziona l'app o semplicemente vuoi avere ulteriori informazioni e/o
              chiarimenti... contattaci!
              <br />
              <br />
              Contattaci anche hai dei suggerimenti su come migliorare <span className='CarboMetro-Writing'>CarboMetro</span>.
              <br />
              <br />
              Ti risponderemo il prima possibile.
            </p>
          </Grid>
          <Grid item xs={12} border={border} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '16px' }}>
            <Button variant="outlined"
              size="large"
              style={{ borderColor: '#ffc107ff', color: '#ffc107ff' }}
              rel="noopener noreferrer"
              href={`mailto:hello@carbometro.it`}>
              Scivici una e.mail
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Support;
