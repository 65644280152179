import Header from './Header';
import '../assets/css/AppCarboMetro.css';

import { Box, Grid } from '@mui/material';
import CarboMetroImageList from './ImageList';
import IOSLogo from '../assets/carbo-metro-images/app-store.png';

function Home() {
  const border = '0px solid';

  return (
    <div className="App-CarboMetro">
      <Box display="flex" justifyContent="center" style={{ paddingTop: '10px' }}>
        <Grid className='app-header-carbo-metro' container spacing={2} border={border}>
          <Grid item xs={12} border={border}>
            <Header titleString="cos'e`, a cosa serve" />
          </Grid>
          <Grid item xs={12} border={border}>
            <h1>Cos'è</h1>
            <p>
              <span className='CarboMetro-Writing'>Carbo Metro</span> &egrave; l'applicazione che ti aiuta nella dieta quotidiana supportandoti nel calcolo delle unità di insulina da somministrare ad ogni pasto.<br />
              L'applicazione sarà a breve disponibile per i dispositivi iOS e, in un secondo momento, per Android.
            </p>
          </Grid>
          {/* <Grid item xs={12} border={border} display='flex' justifyContent={'center'} >
            <a href='https://www.google.com/' target='_blank'>
              <img src={IOSLogo} alt='Versione iOS' width={'150px'} />
            </a>
          </Grid> */}
          <Grid item xs={12} border={border}>
            <p>
              <h1>A cosa serve</h1>
              Dopo una semplice configurazione dei parametri principali (CHO per gr, fattore di CHO e glicemia target a digiuno), ti sar&agrave; sufficiente inserire i valori di glicemia a digiuno e la lista degli alimenti che compongono il tuo pasto e <span className='CarboMetro-Writing'>Carbo Metro</span> ti indicher&agrave; il numero di unit&agrave; di insulina da somministrare. Puoi usare <span className='CarboMetro-Writing'>Carbo Metro</span> anche per calcolare le unit&agrave; di insulina da somministrare per un pasto che hai gi&agrave; consumato.<br />
            </p>
          </Grid>
          <Grid item xs={12} border={border}>
            <h1>Aspetto</h1>
            <CarboMetroImageList />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Home;
