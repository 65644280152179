import screenshot_1 from '../assets/carbo-metro-images/screenshot/screenshot_1.png';
import screenshot_2 from '../assets/carbo-metro-images/screenshot/screenshot_2.png';
import screenshot_3 from '../assets/carbo-metro-images/screenshot/screenshot_3.png';
import screenshot_4 from '../assets/carbo-metro-images/screenshot/screenshot_4.png';
import screenshot_5 from '../assets/carbo-metro-images/screenshot/screenshot_5.png';
import screenshot_6 from '../assets/carbo-metro-images/screenshot/screenshot_6.png';
import screenshot_7 from '../assets/carbo-metro-images/screenshot/screenshot_7.png';
import screenshot_8 from '../assets/carbo-metro-images/screenshot/screenshot_8.png';
import screenshot_9 from '../assets/carbo-metro-images/screenshot/screenshot_9.png';

export default function CarboMetroImageList() {
  return (
    <>
      {itemData.map((item: any) => (
        <img
          width={250}
          style={{ padding: '15px' }}
          src={item.img}
          alt={item.title}
          loading="lazy"
        />
      ))
      }
    </>

  );
}

const itemData = [
  {
    img: screenshot_1,
    title: 'Bed',
  },
  {
    img: screenshot_2,
    title: 'Bed',
  },
  {
    img: screenshot_3,
    title: 'Bed',
  },
  {
    img: screenshot_4,
    title: 'Bed',
  },
  {
    img: screenshot_5,
    title: 'Bed',
  },
  {
    img: screenshot_6,
    title: 'Bed',
  },
  {
    img: screenshot_7,
    title: 'Bed',
  },
  {
    img: screenshot_8,
    title: 'Bed',
  },
  {
    img: screenshot_9,
    title: 'Bed',
  },
];
