import Header from './Header';
import '../assets/css/AppCarboMetro.css';

import { Box, Grid, Button } from '@mui/material';

function TermAndUseConditions() {
  const border = '0px solid';

  return (
    <div className="App-CarboMetro">
      <Box display="flex" justifyContent="center" style={{ paddingTop: '10px' }}>
        <Grid className='app-header-carbo-metro' container spacing={2} border={border}>
          <Grid item xs={12} border={border}>
            <Header titleString="termini e condizioni d'uso" />
          </Grid>
          <Grid item xs={12} border={border}>
            {/* <h1>Contattaci</h1>
            <p>
              Se hai dei dubbi su come funziona l'app o semplicemente vuoi avere ulteriori informazioni e/o
              chiarimenti... contattaci!
              <br />
              <br />
              Contattaci anche hai dei suggerimenti su come migliorare <span className='CarboMetro-Writing'>CarboMetro</span>.
              <br />
              <br />
              Ti risponderemo il prima possibile.
            </p> */}
            <p>
              Ultimo aggiornamento: <span className='CarboMetro-Writing'>26.09.2023</span><br />
              <br />
              Benvenuto nell'applicazione mobile <span className='CarboMetro-Writing'>Carbo Metro</span>. Prima di utilizzare l'applicazione, ti preghiamo di leggere attentamente i seguenti Termini e Condizioni d'Uso ("Termini"). L'uso dell'applicazione implica la tua accettazione incondizionata dei seguenti Termini. Se non accetti questi Termini, ti invitiamo a non utilizzare l'applicazione.

              <h1>1. Descrizione dell'Applicazione</h1>
              <span className='CarboMetro-Writing'>Carbo Metro</span> è un'applicazione mobile progettata per aiutare gli utenti a calcolare le unit&agrave; di insulina necessarie in base al cibo che compone un pasto. L'applicazione non è destinata a sostituire consigli medici professionali. Si tratta di uno strumento di aiuto personale e non dovrebbe essere utilizzato come sostituto per consulti medici o professionali.

              <h1>2. Utilizzo dell'Applicazione</h1>
              L'utente riconosce che l'uso dell'applicazione è a proprio rischio e discrezione. L'applicazione fornisce stime basate sui dati inseriti dall'utente e non può garantire la precisione delle informazioni fornite. L'utente è responsabile di verificare l'accuratezza delle stime e di consultare un medico o un professionista sanitario prima di apportare cambiamenti significativi alla propria terapia insulinica.

              <h1>3. Dati Personali e Privacy</h1>
              L'applicazione può richiedere l'accesso a dati personali, come informazioni sul cibo consumato e altri dati relativi alla gestione del diabete. Si informa l'utente che tali dati ad oggi non sono raccolti e utilizzati al di fuori dell'applicazione stessa. I dati non vengono trasmessi in alcun modo a servizi o server afferenti a <span className='CarboMetro-Writing'>Carbo Metro</span> o a terze parti.

              <h1>4. Proprieta` Intellettuale</h1>
              L'applicazione e tutti i contenuti ad essa associati, incluse immagini, testi, grafiche e altro materiale, sono di propriet&agrave; esclusiva del detentore dei diritti. L'utente accetta di non copiare, distribuire, riprodurre o utilizzare in alcun modo i contenuti dell'applicazione senza il consenso esplicito del detentore dei diritti.

              <h1>5. Modifiche ai Termini</h1>
              L'amministrazione si riserva il diritto di modificare o aggiornare questi Termini in qualsiasi momento senza preavviso. L'utente è responsabile di verificare periodicamente i Termini per essere consapevole delle eventuali modifiche.

              <h1>6. Limitazione di Responsabilita`</h1>
              In nessun caso l'amministrazione dell'applicazione sar&agrave; responsabile per danni diretti, indiretti, incidentali o consequenziali derivanti dall'uso o dall'incapacit&agrave; di utilizzare l'applicazione.

              <h1>7. Legge Applicabile</h1>
              Questi Termini sono disciplinati e interpretati in conformit&agrave; con le leggi Italiane.

              <h1>8. Contatti</h1>
              Per domande o commenti relativi a questi Termini, si prega di contattare <a href='mailto:hello@carbometro.it'>hello@carbometro.it</a><br />
              <br />
              Accettando questi Termini, l'utente conferma di aver letto e compreso l'intero contenuto e accetta di essere vincolato da essi.
            </p>
          </Grid>
          {/* <Grid item xs={12} border={border} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined"
              size="large"
              style={{ borderColor: '#ffc107ff', color: '#ffc107ff' }}
              rel="noopener noreferrer"
              href={`mailto:test@example.com`}>
              Scivici una e.mail
            </Button>
          </Grid> */}
        </Grid>
      </Box>
    </div>
  );
}

export default TermAndUseConditions;
