import { FC, ReactElement, useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

const packageJson = require('../../package.json');

export const Footer = (): ReactElement => {
	const border = '0px solid';

	return (
		<Box
			sx={{
				position: "fixed",
				bottom: 0,
				width: "100%",
				height: '45px',
				backgroundColor: "#ffc107ff",
				padding: "5px",
			}}
		>
			<Container maxWidth='xl'>
				<Grid container display={'flex'} alignItems="center" direction="row" >
					<Grid item xs={12} display={'flex'} alignItems="center" border={{ border }}>
						<Typography variant="subtitle1">
							<center>
								<span className='CarboMetro-Writing-Footer' style={{ font: '#00000' }}>Carbo Metro</span> <b>&copy; 2023</b>
							</center>
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>

	);
};

export default Footer;
